var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import UsersControlHeader from '@/modules/users/components/UserControlHeader.vue';
let UserControlPanelTabs = class UserControlPanelTabs extends Vue {
    constructor() {
        super(...arguments);
        this.tabTitle = this.tabs[0].text;
        this.splitterModel = 100;
        this.currentTabs = [];
    }
    rulesToAccessTab(tabName) {
        return tabName == 'Integração com CRM' && this.userSankhyaAllow;
    }
    get userSankhyaAllow() {
        return (
        // @ts-ignore
        this.$auth.check('prospector') &&
            // @ts-ignore
            this.$auth.user().email.match('@sankhya.com.br'));
    }
    handleTabs() {
        // @ts-ignore
        if (this.$auth.check(['admin', 'owner', 'manager', 'customer_success'])) {
            return this.currentTabs;
        }
        else {
            return Array.from([this.currentTabs[0], this.currentTabs[2]]);
        }
    }
    getUserSpecialConditions() {
        if (this.currentUser.email.match(new RegExp(/(speedio|speediomail|oracle|movetruly)\b/, 'gi'))) {
            return true;
        }
        else {
            return false;
        }
    }
    logout() {
        return true;
    }
};
__decorate([
    Prop()
], UserControlPanelTabs.prototype, "tabs", void 0);
__decorate([
    Prop({ default: false })
], UserControlPanelTabs.prototype, "centered", void 0);
__decorate([
    Prop({ default: true })
], UserControlPanelTabs.prototype, "vertical", void 0);
__decorate([
    Prop({ default: false })
], UserControlPanelTabs.prototype, "tabsAlignLeft", void 0);
__decorate([
    Prop({ default: false })
], UserControlPanelTabs.prototype, "large", void 0);
__decorate([
    Prop({ default: true })
], UserControlPanelTabs.prototype, "inlineLabel", void 0);
__decorate([
    Prop({
        default: () => { },
    })
], UserControlPanelTabs.prototype, "currentUser", void 0);
__decorate([
    Prop({ default: false })
], UserControlPanelTabs.prototype, "activeConfig", void 0);
__decorate([
    Emit('logout')
], UserControlPanelTabs.prototype, "logout", null);
UserControlPanelTabs = __decorate([
    Component({
        name: 'UserControlPanelTabs',
        components: {
            UsersControlHeader,
        },
    })
], UserControlPanelTabs);
export default UserControlPanelTabs;
