var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import IconSpeedioLogoBgGrey from '@/components/Icons/icon-speedio-logo-bg-grey.vue';
let UserControlHeader = class UserControlHeader extends Vue {
    get name() {
        const nameUser = this.userName.split(' ');
        return nameUser[0];
    }
    get typeUser() {
        // @ts-ignore
        if (this.$auth.user()) {
            // @ts-ignore
            switch (this.$auth.user().role) {
                case 'admin':
                    return 'Admin';
                case 'owner':
                    return 'Proprietário';
                case 'manager':
                    return 'Gerente';
                case 'coordinator':
                    return 'Coordenador';
                case 'prospector':
                    return 'Prospector';
                case 'customer_success':
                    return 'CS';
                default:
                    return '';
            }
        }
        else {
            return '';
        }
    }
    logout() {
        return true;
    }
};
__decorate([
    Prop({ default: '' })
], UserControlHeader.prototype, "userName", void 0);
__decorate([
    Emit('logout')
], UserControlHeader.prototype, "logout", null);
UserControlHeader = __decorate([
    Component({
        name: 'UserControlHeader',
        components: {
            IconSpeedioLogoBgGrey,
        },
    })
], UserControlHeader);
export default UserControlHeader;
