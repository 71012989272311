import { render, staticRenderFns } from "./UserControlHeader.vue?vue&type=template&id=284352e6&scoped=true&"
import script from "./UserControlHeader.vue?vue&type=script&lang=ts&"
export * from "./UserControlHeader.vue?vue&type=script&lang=ts&"
import style0 from "./UserControlHeader.vue?vue&type=style&index=0&id=284352e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284352e6",
  null
  
)

export default component.exports