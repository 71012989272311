var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative-position fit"},[_c('div',{staticClass:"UserControlContainer",staticStyle:{"background-color":"#f4f4f6"}},[_c('div',{staticClass:"UserControlTabs border-right",attrs:{"data-cy":"user-tab-container"}},[_c('header',{attrs:{"id":"user-control--header"}},[_c('UsersControlHeader',{attrs:{"userName":_vm.currentUser.name},on:{"logout":_vm.logout}})],1),_c('q-scroll-area',{staticClass:"fit column",attrs:{"thumb-style":{
          right: '0',
          borderRadius: '5px',
          backgroundColor: '#CFD8DC',
          width: '5px',
          opacity: 0.75,
        }}},[_c('div',{staticClass:"col-auto"},[_c('q-tabs',{staticClass:"text-primary fit",attrs:{"centered":"","vertical":"","inline-label":_vm.inlineLabel,"stretch":"","switch-indicator":"","animated":""}},[_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index},[_c('q-route-tab',{key:index,staticClass:"border-bottom UserControlTabItem",attrs:{"data-cy":"user-tab-list","to":_vm.rulesToAccessTab(tab.name) ? '' : { name: tab.to },"icon":tab.icon,"label":tab.name,"name":tab.name,"disabled":_vm.rulesToAccessTab(tab.name),"no-caps":""}})],1)})],2)],1)])],1),_c('div',{staticClass:"UserControlContent fit"},[_c('q-scroll-area',{staticClass:"fit q-pr-md q-pt-md",attrs:{"thumb-style":{
          right: '0',
          borderRadius: '5px',
          backgroundColor: '#606A6C',
          width: '6px',
          opacity: 0.45,
        }}},[_c('router-view')],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }