var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import UserControlPanelTabs from '../components/UserControlPanelTabs.vue';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
let UserContainer = class UserContainer extends Vue {
    constructor() {
        super(...arguments);
        this.user = {};
        this.showMyPreferences = false;
        this.tabs = [
            {
                to: 'overview',
                text: 'Visão geral da conta',
                icon: 'icon-overview',
                name: 'Visão geral da conta',
            },
            {
                to: 'adminpanel',
                text: 'Gerenciamento de usuários',
                icon: 'icon-manager-profile',
                name: 'Gerenciamento de usuários',
            },
            {
                to: 'password',
                text: 'Alterar senha',
                icon: 'icon-password',
                name: 'Alterar senha',
            },
            {
                to: 'apiTokenExport',
                text: 'Integração com CRM',
                icon: 'icon-key',
                name: 'Integração com CRM',
            },
            {
                to: 'share-and-win',
                text: 'Indique e ganhe',
                icon: 'img:statics/images/icon-gift.svg',
                name: 'Indique e ganhe',
            },
        ];
    }
    verifyAuth() {
        // @ts-ignore TS2349: property inexistent
        if (this.state.statusResponse.code === 401) {
            // @ts-ignore TS2349: property inexistent
            this.$auth.logout();
        }
    }
    async mounted() {
        // @ts-ignore TS2349: property inexistent
        if (isEmpty(this.state.currentUser)) {
            // @ts-ignore TS2349: property inexistent
            this.user = this.$auth.user();
        }
        this.verifyAuth();
        // @ts-ignore TS2349: property inexistent
        const result = await this.actions.getAnswersWelcomeScreen();
        this.showMyPreferences = !!(result.data && result.data.value);
        this.handleMyPreferencesTab();
        this.handleCloserFormTab();
        // @ts-ignore TS2349: property inexistent
        await this.actions.getSubUsers();
        // @ts-ignore TS2349: property inexistent
        await this.actions.getUserInformation();
        // @ts-ignore TS2349: property inexistent
        await this.actions.getUserMe();
    }
    handleMyPreferencesTab() {
        if (this.showMyPreferences) {
            this.tabs.push({
                to: 'my_preferences',
                text: 'Meu perfil de cliente ideal',
                icon: 'img:statics/images/star.svg',
                name: 'Meu perfil de cliente ideal',
            });
        }
    }
    handleCloserFormTab() {
        // hidden because agile integration was declined
        // @ts-ignore TS2349: property inexistent
        // if (this.state.currentUser.email.includes('speedio.com.br')) {
        //   this.tabs.push({
        //     to: 'engage',
        //     text: 'Formulário de contratação',
        //     icon: 'img:statics/images/paste.svg',
        //     name: 'Formulário de contratação',
        //   })
        // }
    }
    async logout() {
        mixpanelTracking('painel do usuário: Logout');
        // @ts-ignore TS2349: property inexistent
        this.infosetsActions.cleanStoreInfosets();
        // @ts-ignore TS2349: property inexistent
        this.actions.cleanStoreUsers();
        localStorage.removeItem('redirect');
        // @ts-ignore TS2349: property inexistent
        //this.$auth.logout();
        this.$router.push({ name: 'login' });
        localStorage.removeItem('auth_token_default');
        localStorage.removeItem('noShowAgainUpgradePlanModalForOneday');
        localStorage.removeItem('closeBannerFastUpgradePlan');
        localStorage.removeItem('contractor_document');
        localStorage.removeItem('contractor_name');
        localStorage.removeItem('business_name');
        localStorage.removeItem('email');
        localStorage.removeItem('street_address');
        localStorage.removeItem('complemental_address');
        localStorage.removeItem('house_number');
        localStorage.removeItem('zipcode');
        localStorage.removeItem('neighborhood');
        localStorage.removeItem('city');
        localStorage.removeItem('state');
        mixpanelTracking('$session_end');
        localStorage.clear();
        sessionStorage.clear();
        // @ts-ignore TS2339: property inexistent
        const dbs = await window.indexedDB.databases();
        dbs.forEach(db => {
            window.indexedDB.deleteDatabase(db.name);
        });
    }
};
UserContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
        infosetsActions: actions.infosets,
    }), {
        name: 'UserContainer',
        components: {
            UserControlPanelTabs,
        },
    }))
], UserContainer);
export default UserContainer;
